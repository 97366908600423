
import {Options, Vue} from 'vue-class-component';
import {UserInfoData} from "@/types/api/UserInfoData";
import UserAvatar from "@/components/UserAvatar.vue";
import {cloneDeep} from 'lodash';
import {
  changePassword,
  checkPhoneCode,
  getEmailCode,
  getPhoneCode,
  getUserInfo,
  updateUserInfo,
  uploadImg
} from "@/api";
import {colorLog} from "@/utils";
import {ElMessage, ElMessageBox, FormRules, UploadFile, UploadRawFile} from "element-plus";
import store from "@/store";
import {JSEncrypt} from "jsencrypt";
import {ComponentPublicInstance} from "vue";
import {VueCropper} from "vue-cropper/next";
import 'vue-cropper/next/dist/index.css'
type UserInfoProps = keyof UserInfoData;


@Options<Profile>({
  components: {UserAvatar,VueCropper},
  props: {}
})
export default class Profile extends Vue {
  initialLoading = false;
  rules!:FormRules;
  userInfo:UserInfoData | null = {
    uid:-1,
    headImgUrl:'',
    userName: '',
    email:'',
    phone:''
  } as UserInfoData;

  newUserInfo:any={
    email:"",
    emailCode:"",
    password:"",
    newPassword:"",
    phoneCode:"",
    headImg:""
  }
  passDialogVisible:boolean=false;
  codeButton:any= {
    isDisabled: false,
    name: '获取验证码',
    time: 60
  };
  timer:number|undefined=undefined;
  emailDialogVisible:boolean=false;
  uploadHeadImgDialogVisible:boolean=false;
  phoneCodeDialogVisible:boolean=false;
  task:string=""

  $refs!:{upload:ComponentPublicInstance &{clearFiles:Function},copper:ComponentPublicInstance&{getCropData:Function}}
  userInfoCopy:UserInfoData | null = null;
  beforeCreate(){
    this.$watch(() => this.$i18n.locale, () => {
      this.rules = {
        userName:[
          { required: true, message: this.$t('formUserNameTip'), trigger: 'blur' },
          {
            pattern: new RegExp('^[a-zA-Z0-9_-]{2,16}$'),
            message: this.$t('formUserNameTip')
          }
        ],
        email:[
          { required: true, message: this.$t('formEmailTip'), trigger: 'blur' },
          {
            pattern:new RegExp('^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$'),
            message: this.$t('formEmailTip'), trigger: 'blur'
          }
        ]
      }
    },{immediate:true})
  }

  async created(){
    this.userInfo = await store.userInfoPromise;
    this.initialLoading = true;
    this.userInfoCopy = cloneDeep(this.userInfo);
  }


  modifyUserInfo(prop:UserInfoProps){
    const diff = this.userInfo![prop] !== this.userInfoCopy![prop];
    if(diff){
      colorLog('modifyUserInfo',prop);
      let params = {
        nickname: this.userInfoCopy![prop],
        uid: this.userInfo?.uid
      };

      updateUserInfo(params).then( async res => {
        if (res.data.code === 0) {
          ElMessage.success("修改成功")
          store.updateUserInfo(getUserInfo())
          this.userInfo = await store.userInfoPromise
        } else {
          ElMessage.error({
            type: 'error',
            message: res.data.msg
          })
        }
      }).catch(res => {
        ElMessage.error({
          type: 'error',
          message: res.msg
        })
      })
    }
  }

  async checkPhoneCode(){
    let params = {
      type: 'sms',
      content: this.newUserInfo.phoneCode
    };

    let res = await checkPhoneCode(params);
    if (res.data.code !== 0) {
      ElMessage.error("验证码错误")
      return;
    }
    this.phoneCodeDialogVisible=false;
    if(this.task==='password'){
      this.passDialogVisible=true;
    }else if(this.task==='email'){
      this.emailDialogVisible=true;
      this.resetCodeButton();
    }
    this.task="";
  }

  async changePasswordFunc() {
    if (this.newUserInfo.password !== this.newUserInfo.newPassword) {
      ElMessage.error({
        type: 'error',
        message: '两次密码不一致'
      })
      return
    }

    let publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCX4kRYjU6XclWT4mwxgQ55JerRar8CPEHp4uZRITqZuk8p0z5UTL02muequw2dgvgNlJkE8C7eyXf1S8TH+MvbVkHnCoKKigAPb08PtqcvVIqnZn+kNDpiQZapkpHrEXeR+rWfj3etTwMHYbV+zTaKOdxa7fm3oO4CWbgmfkSC8wIDAQAB'
    let encryptor = new JSEncrypt()
    encryptor.setPublicKey(publicKey)

    let pwd =  encryptor.encrypt(this.newUserInfo.password)
    if(pwd) {
      changePassword(pwd).then(res => {
        if (res.data.code === 0) {
          ElMessage.success("success");
          this.passDialogVisible = false
        } else {
          ElMessage.error({
            type: 'error',
            message: res.data.msg
          })
        }
      }).catch(res => {
        ElMessage.error({
          type: 'error',
          message: '密码更新失败'
        })
      })
    }

  }

  getPhoneCode(loginType:string) {
    this.codeButton.isDisabled = true;
    let type = loginType
    let params = {
      phone: this.newUserInfo.country_code === '' || this.newUserInfo.country_code === undefined
          ? '86-' + this.userInfo?.phone : this.newUserInfo.country_code + '-' + this.userInfo?.phone
    }
    getPhoneCode(type, params).then(res => {
      if (res.data.code === 0) {
        this.timer = window.setInterval(() => {
          this.codeButton.name = '(' + this.codeButton.time + '秒)后重发';
          --this.codeButton.time;
          if (this.codeButton.time < 0) {
            this.resetCodeButton();
          }
        }, 1000);
      } else if (res.data.code === 5) {
        ElMessageBox.confirm(res.data.msg, '提示', {
          confirmButtonText: '确定',
          // eslint-disable-next-line no-unused-vars
          callback: (_:any) => {
            this.timer = window.setInterval(() => {
              this.codeButton.name = '(' + this.codeButton.time + '秒)后重发';
              --this.codeButton.time;
              if (this.codeButton.time < 0) {
                this.resetCodeButton();
              }
            }, 1000);
          },
        });
      }

    })
  }

  getEmailCode(){
    getEmailCode("new_email",{email:this.newUserInfo.email}).then(res=>{
      if (res.data.code === 0) {
        ElMessage.success("验证码已发送，请查看邮箱")
      }else{
        ElMessage.error("获取验证码失败")
      }
    })
  }
  resetCodeButton(){
    this.codeButton = {
      isDisabled: false,
      name: '获取验证码',
      time: 60
    };

    window.clearInterval(this.timer);
  }


  async updateUserInfoFunc(callback:Function) {


    let emailCodeParams = {
      type: 'email',
      content: this.newUserInfo.emailCode
    };

    let res = await checkPhoneCode(emailCodeParams);
    if (res.data.code !== 0) {
      ElMessage.error("验证码错误")
      return;
    }

    let params = {
      email: this.newUserInfo.email,
      uid: this.userInfo?.uid
    };

    updateUserInfo(params).then( async res => {
      if (res.data.code === 0) {
        ElMessage.success("修改成功")
        this.emailDialogVisible=false;
        if(this.userInfo) {
          this.userInfo.email = this.newUserInfo.email;
        }
        store.updateUserInfo(getUserInfo())
        this.userInfo = await store.userInfoPromise

      } else {
        ElMessage.error({
          type: 'error',
          message: res.data.msg
        })
      }
    }).catch(res => {
      ElMessage.error({
        type: 'error',
        message: res.msg
      })
    })
  }

  trimOrSetEmpty (param:any) {
    if (param === null || param === undefined) {
      return param
    } else {
      return param.trim()
    }
  }

   onChange(file:UploadFile) {

    console.log(file)

     this.uploadHeadImgDialogVisible=true;
     let reader = new FileReader()
     reader.onload = (e:ProgressEvent<FileReader>) => {
       let data
       if (typeof e.target?.result === 'object') {
         if(e.target.result) {
           data = window.URL.createObjectURL(new Blob([e.target.result]))
         }
       } else {
         data = e.target?.result
       }
       this.newUserInfo.headImg = data
     }
     //转化为base64
     reader.readAsDataURL(file.raw as Blob)

  }

  updateHeadImg(){

    this.$refs.copper.getCropData( async (data:any)=>{
      let params = {
        src: '',
        data: data,
        acl: 'read'
      }
      uploadImg(params).then(res=>{
        if (res.data.code === 0) {
          let pa = {
            headimgurl: res.data.key,
            uid: this.userInfo?.uid
          }
          updateUserInfo(pa).then( async res => {
            if (res.data.code === 0) {
              this.uploadHeadImgDialogVisible = false
              store.updateUserInfo(getUserInfo())
              this.userInfo = await store.userInfoPromise
            }
          })
        }
      })
    });


  }

}
